<script>
import AcceptInvitationForm from '../../../forms/accept_invitation';
import BaseRegistrationForm from './BaseRegistrationForm.vue';

export default {
  extends: BaseRegistrationForm,
  data() {
    return {
      form: new AcceptInvitationForm(this.$route.params.token),
    };
  },
  computed: {
    displayPasswordField() {
      return true;
    },
  },
  methods: {
    afterSuccess() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
