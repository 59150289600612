<template>
  <form @submit.prevent="submit">
    <PhoneConfirmationForm
      v-model="form.fields.resetCode"
      :loading="form.loading"
      :button-label="$t('two_fa.reset')"
      :errors="form.errors.resetCode"
      @submit="submit"
    >
      <Button
        class="button button__link"
        :label="$t('common.cancel')"
        @click.native="close"
      />
    </PhoneConfirmationForm>
  </form>
</template>

<script>
import Button from '../../elements/common/buttons/Button.vue';
import PhoneConfirmationForm from '../../elements/two_fa/PhoneConfirmationForm.vue';
import ResetTwoFactorForm from '../../../forms/reset_two_factor';

export default {
  components: {
    PhoneConfirmationForm,
    Button,
  },
  data() {
    return {
      form: new ResetTwoFactorForm(),
    };
  },
  methods: {
    async submit() {
      if (this.form.loading) {
        return;
      }
      const response = await this.form.submit();

      if (this.form.success) {
        this.$emit('qr', response.qr);
        this.$router.push({ name: 'reset_2fa_success' });
      }
    },
    close() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
