import BaseForm from './base';
import authRepository from '../repositories/auth_repository';

export default class LoginOtp extends BaseForm {
  errors = [];
  fields = {
    code: '',
  };

  clearErrors() {
    this.errors = [];
  }

  action() {
    return authRepository.signIn({ user: this.fields });
  }
}
