<template>
  <div class="accept-invitation">
    <div class="center-form">
      <img src="../../../assets/images/logos/measure-studio-logo-text.svg" class="content__paladin-logo">
      <div class="overlay__title">
        {{ $t('register.welcome') }}
      </div>
      <div class="overlay__text">
        {{ $t('register.social_media_intelligence') }}
      </div>

      <div class="overlay__form-wrapper">
        <SsoCreateProfileForm />
      </div>

      <div class="register__go-to-sign-in">
        {{ $t('register.already_have_account') }}
        <RouterLink :to="{name: 'login'}" class="login-header__link">
          {{ $t('register.sign_in') }}
        </RouterLink>
      </div>
    </div>

    <Footer class="footer--pre-pages" />
  </div>
</template>

<script>
import SsoCreateProfileForm from '../../elements/members/SsoCreateProfileForm.vue';
import Footer from '../../elements/layout/Footer.vue';

export default {
  components: {
    SsoCreateProfileForm,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
  .accept-invitation {
    background-color: #e5ebf0;
  }
</style>
