import Vue from 'vue';
import VTooltip from 'v-tooltip';
import moment from 'moment';
import VueCookies from 'vue-cookies';
import 'moment-timezone';

import ExtendedVueSelect from './components/elements/common/ExtendedVueSelect';
import Auth from './components/Auth.vue';
import router from './routers/auth';
import i18n from './config/i18n';
import './config/base';
import './config/error_tracker';
import './config/intercom';
import './config/facebook_pixel';
import './config/linkedin_pixel';
import './config/analytics';

import './utils/filters';
import quickNotice from './utils/quick_notice';

Vue.component('VSelect', ExtendedVueSelect);

Vue.use(quickNotice, {});
Vue.use(VTooltip);
Vue.use(VueCookies);
VTooltip.enabled = window.innerWidth > 768;

moment.tz.setDefault('America/Los_Angeles');
window.EventBus = new Vue();

const app = new Vue({
  router,
  i18n,
  render: h => h(Auth),
}).$mount('#auth');

export default app;
