<template>
  <div />
</template>
<script>
export default {
  created() {
    this.$quickNotice.notify({ type: 'error', body: this.$t('server_error') });
    this.$router.push({ name: 'login' });
  },
};
</script>
