<template>
  <div>
    <div class="overlay__title">
      {{ $t('two_fa.authentication_code') }}
    </div>
    <div class="overlay__text">
      {{ $t('two_fa.open_device_input_code') }}
    </div>
    <div class="overlay__form-wrapper">
      <TwoFaCode @success="$emit('success', $event)" />
    </div>
  </div>
</template>

<script>
import TwoFaCode from '../../elements/auth/login/TwoFaCode.vue';

export default {
  components: {
    TwoFaCode,
  },
};
</script>
