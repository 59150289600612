<template>
  <div v-if="form.currentData" class="registration-form">
    <AvatarPicker v-model="form.fields.avatar" />

    <InputBuilder
      v-model.lazy="form.fields.fullName"
      name="fullName"
      :label="$t('register.full_name.label')"
      :placeholder="$t('register.full_name.placeholder')"
      :errors="form.errors.fullName"
      class="input-settings"
      required
    />

    <div class="member-details__info-label">
      {{ $t('register.email.label') }}
    </div>
    <div class="member-details__info-detail">
      {{ form.currentData.email }}
    </div>

    <div class="member-details__info-description">
      {{ $t('register.edit_email') }}
    </div>

    <InputBuilder
      v-if="displayPasswordField"
      v-model.lazy="form.fields.password"
      name="password"
      :label="$t('register.password.label')"
      :placeholder="$t('register.password.placeholder')"
      :errors="form.errors.password"
      type="password"
      class="input-settings"
      required
    />
    <InputBuilder
      v-if="displayPasswordField"
      v-model.lazy="form.fields.passwordConfirmation"
      name="passwordConfirmation"
      :label="$t('register.password_confirmation.label')"
      :placeholder="$t('register.password_confirmation.placeholder')"
      :errors="form.errors.passwordConfirmation"
      type="password"
      class="input-settings"
      required
    />
    <InputBuilder
      v-model.lazy="form.fields.phone"
      :label="$t('profile.fields.phone.label')"
      :errors="form.errors.phone"
      class="input-settings"
    >
      <PhoneInput
        v-model="form.fields.phone"
        :placeholder="$t('profile.fields.phone.placeholder')"
        name="phone"
        class="input-settings--phone"
      />
    </InputBuilder>

    <div v-if="form.currentData.company" class="member-details__info-wrapper">
      <div class="member-details__info-label">
        {{ $t('register.company.label') }}
      </div>
      <div class="member-details__info-detail">
        {{ form.currentData.company }}
      </div>
    </div>

    <div class="checkbox__container">
      <InputError :errors="form.errors.acceptTerms">
        <Checkbox
          id="acceptTerms"
          v-model="form.fields.acceptTerms"
          name="acceptTerms"
          class="checkbox__terms"
        >
          <span v-html="$t('register.terms.label')" />
        </Checkbox>
      </InputError>
    </div>

    <div v-if="form.currentData.bulkLicense" class="checkbox__container">
      <InputError :errors="form.errors.acceptBulkLicense">
        <Checkbox
          id="acceptBulkLicense"
          v-model="form.fields.acceptBulkLicense"
          name="acceptBulkLicense"
          class="checkbox__terms"
        >
          <span
            v-html="$t('register.bulk_license_acknowledgment.label',
                       { company: $options.filters.escape(form.currentData.company) })"
          />
        </Checkbox>
      </InputError>
    </div>

    <div class="checkbox__container">
      <Checkbox
        id="mailingConsent"
        v-model="form.fields.marketingMailingConsent"
        name="mailingConsent"
        :label="$t('register.updates.label')"
        class="checkbox__updates"
      />
    </div>

    <div
      v-tooltip="!form.canBeSubmitted() && submitTooltip"
      class="registration-form__submit"
    >
      <Button
        :disabled="!form.canBeSubmitted()"
        :label="$t('register.create_account')"
        class="button__primary button__primary--wide"
        :loading="form.loading"
        @click.native="submit"
      />
    </div>
  </div>
</template>

<script>
import InputBuilder from '../common/inputs/InputBuilder.vue';
import InputError from '../common/inputs/InputError.vue';
import Button from '../common/buttons/Button.vue';
import Checkbox from '../common/forms/Checkbox.vue';
import PhoneInput from '../common/inputs/PhoneInput.vue';
import AvatarPicker from '../common/pickers/AvatarPicker.vue';

export default {
  components: {
    InputBuilder,
    InputError,
    Button,
    Checkbox,
    PhoneInput,
    AvatarPicker,
  },
  computed: {
    submitTooltip() {
      if (this.form.currentData.bulkLicense) {
        return `${this.$t('tooltips.terms')} ${this.$t('tooltips.bulk_license_acknowledgment')}`;
      }

      return this.$t('tooltips.terms');
    },
  },
  methods: {
    async submit() {
      await this.form.submit();

      if (this.form.success) {
        this.$quickNotice.notify({
          type: 'success',
          body: this.$t('register.please_log_in'),
        });
        this.afterSuccess();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .registration-form {
    max-width: 404px;

    ::v-deep .image-preview {
      border: 2px #e5ebf0 solid;
    }

    .member-details__info-detail {
      margin-bottom: 10px;
    }

    .member-details__info-description {
      margin-bottom: 30px;
    }
  }
</style>
