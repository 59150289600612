<template>
  <form @submit.prevent="submit">
    <CodeWithQrForm
      v-model="form.fields.code"
      :loading="form.loading"
      :errors="form.errors"
      :qr="qr"
      @submit="submit"
    />
  </form>
</template>

<script>
import LoginOtpForm from '../../../forms/login_otp';
import CodeWithQrForm from '../../elements/two_fa/CodeWithQrForm.vue';

export default {
  components: {
    CodeWithQrForm,
  },
  props: {
    qr: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: new LoginOtpForm(),
    };
  },
  methods: {
    async submit() {
      if (this.form.loading) {
        return;
      }
      const response = await this.form.submit();

      if (this.form.success) {
        this.$emit('success', response);
      }
    },
    close() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
