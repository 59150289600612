<template>
  <form @submit.prevent="submit">
    <InputBuilder
      v-model.lazy="form.fields.email"
      name="email"
      :label="$t('forgot_password.email.label')"
      :placeholder="$t('forgot_password.email.placeholder')"
      :errors="form.errors.email"
      class="input-settings"
      required
    />

    <div class="overlay__button-wrapper">
      <Button
        :label="$t('forgot_password.reset_password')"
        class="button button__primary"
        type="submit"
        :loading="form.loading"
      />
    </div>
  </form>
</template>

<script>
import InputBuilder from '../../common/inputs/InputBuilder.vue';
import Button from '../../common/buttons/Button.vue';
import ForgotPasswordForm from '../../../../forms/forgot_password';

export default {
  components: {
    InputBuilder,
    Button,
  },
  data() {
    return {
      form: new ForgotPasswordForm(),
    };
  },
  methods: {
    async submit() {
      if (this.form.loading) {
        return;
      }

      await this.form.submit();

      if (this.form.success) {
        this.$emit('success');
      }
    },
  },
};
</script>

<style lang="scss">
  .forgot-password-form__container {
    width: 400px;
    padding: 20px;

    @media only screen and (max-width: 425px) {
      width: 300px;
    }
  }
</style>
