<template>
  <div class="content-wrapper">
    <RouterView />

    <QuickNotice />
  </div>
</template>
<script>
import QuickNotice from './elements/common/QuickNotice.vue';

export default {
  components: {
    QuickNotice,
  },
  created() {
    window.EventBus.$on('server-error', () => {
      this.$quickNotice.notify({ type: 'error', body: this.$t('server_error') });
    });

    window.EventBus.$on('not-found-error', () => {
      this.$router.push({ name: 'not_found' });
    });

    window.EventBus.$on('too-many-requests-error', () => {
      this.$quickNotice.notify({ type: 'error', body: this.$t('too_many_requests') });
    });
  },
  mounted() {
    if (process.env.RAILS_ENV === 'production' || process.env.RAILS_ENV === 'staging') {
      this.$intercom.boot({ hide_default_launcher: true });
    }
  },
};
</script>

<style lang="scss">
  @import '../assets/styles/main';
</style>
