<template>
  <div>
    <InputError :errors="errors">
      <label :for="name">
        <slot name="label">
          {{ label }}
          <sup v-if="required">
            *
          </sup>
        </slot>
      </label>
      <v-select
        :id="name"
        :value="value"
        :name="name"
        :options="availableOptions"
        :searchable="false"
        index="value"
        class="v-select--bordered"
        @input="$emit('input', $event)"
      >
        <template slot="selected-option">
          {{ selectedOption }}
        </template>
      </v-select>
    </InputError>
  </div>
</template>
<script>
import _ from 'lodash';
import InputError from './InputError.vue';

export default {
  components: { InputError },
  props: {
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    availableOptions: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    selectedOption() {
      if (!this.value) {
        return '';
      }
      return _.find(this.availableOptions, { value: this.value }).label;
    },
  },
};
</script>
<style scoped lang="scss">
  input {
    outline: none;
  }

  sup {
    color: #ff2373;
  }
</style>
