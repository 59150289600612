import { render, staticRenderFns } from "./TwoFaCode.vue?vue&type=template&id=f3bd3174&scoped=true&"
import script from "./TwoFaCode.vue?vue&type=script&lang=js&"
export * from "./TwoFaCode.vue?vue&type=script&lang=js&"
import style0 from "./TwoFaCode.vue?vue&type=style&index=0&id=f3bd3174&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3bd3174",
  null
  
)

export default component.exports