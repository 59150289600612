import moment from 'moment-timezone';
import BaseForm from './base';
import ssoUsersRepository from '../repositories/sso_users_repository';

export default class SsoCreateProfile extends BaseForm {
  token = null;
  currentData = null;
  timezone = null;
  fields = {
    avatar: '',
    fullName: '',
    phone: '',
    acceptTerms: false,
    acceptBulkLicense: false,
    marketingMailingConsent: false,
  };

  constructor() {
    super();

    ssoUsersRepository.fetchCurrentData()
      .then((data) => {
        this.currentData = data;
      });

    this.timezone = moment.tz.guess();
  }

  canBeSubmitted() {
    if (this.currentData.bulkLicense) {
      return this.fields.acceptTerms && this.fields.acceptBulkLicense;
    }

    return this.fields.acceptTerms;
  }

  action() {
    return ssoUsersRepository.register({
      params: {
        ...this.fields, timezone: this.timezone,
      },
    });
  }
}
