<template>
  <div>
    <template v-if="success">
      <div class="overlay__title">
        {{ $t('forgot_password.we_sent_you_email') }}
      </div>
      <div class="overlay__text">
        {{ $t('forgot_password.follow_instructions') }}
      </div>
    </template>
    <template v-else>
      <div class="overlay__title">
        {{ $t('forgot_password.ops_you_forgot') }}
      </div>
      <div class="overlay__text">
        {{ $t('forgot_password.please_enter_email') }}
      </div>

      <div class="overlay__form-wrapper">
        <ForgotPasswordForm @success="success = true" />
      </div>
    </template>
  </div>
</template>
<script>
import ForgotPasswordForm from '../../elements/auth/forgot_password/ForgotPasswordForm.vue';

export default {
  components: {
    ForgotPasswordForm,
  },
  data() {
    return {
      success: false,
    };
  },
};
</script>
