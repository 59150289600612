import moment from 'moment-timezone';
import BaseForm from './base';
import authRepository from '../repositories/auth_repository';

export default class Register extends BaseForm {
  referrerLink = null;
  timezone = null;
  fields = {
    email: '',
    password: '',
    passwordConfirmation: '',
    company: '',
    companyType: '',
    companySize: '',
    socialsAmount: null,
    socialsTeamSize: null,
    fullName: '',
    phone: '',
    marketingMailingConsent: false,
    acceptTerms: false,
  };

  constructor() {
    super();
    this.timezone = moment.tz.guess();
  }

  action() {
    return authRepository.signUp({
      user: {
        ...this.fields,
        referrerLink: this.referrerLink,
        timezone: this.timezone,
      },
    });
  }
}
