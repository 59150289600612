import Vue from 'vue';
import VueRouter from 'vue-router';

import AuthLogin from '../components/pages/auth/Login.vue';
import AuthLoginWrap from '../components/pages/auth/LoginWrap.vue';
import AuthLoginOtp from '../components/pages/auth/LoginOtp.vue';
import AuthRegister from '../components/pages/auth/Register.vue';
import AuthResetTwoFa from '../components/pages/auth/ResetTwoFa.vue';
import AuthResetTwoFaSuccess from '../components/pages/auth/ResetTwoFaSuccess.vue';
import RegistrationSuccessful from '../components/pages/auth/RegistrationSuccessful.vue';
import AuthForgotPassword from '../components/pages/auth/ForgotPassword.vue';
import AuthResetPassword from '../components/pages/auth/ResetPassword.vue';
import NotFound from '../components/pages/NotFound.vue';
import MembersAcceptInvitation from '../components/pages/members/AcceptInvitation.vue';
import SsoCreateProfile from '../components/pages/members/SsoCreateProfile.vue';
import Failure from '../components/pages/auth/Failure.vue';

Vue.use(VueRouter);

const app = new VueRouter({
  mode: 'history',
  base: '/auth',
  routes: [
    {
      path: '/',
      redirect: '/login',
    },
    {
      path: '/login',
      component: AuthLoginWrap,
      children: [
        {
          path: '',
          component: AuthLogin,
          name: 'login',
        },
        {
          path: 'otp',
          name: 'login_otp',
          component: AuthLoginOtp,
        },
        {
          path: '/forgot_password',
          name: 'forgot_password',
          component: AuthForgotPassword,
        },
        {
          path: '/reset_password/:token',
          name: 'reset_password',
          component: AuthResetPassword,
        },
        {
          path: '/reset_2fa',
          name: 'reset_2fa',
          component: AuthResetTwoFa,
        },
        {
          path: '/reset_2fa/success',
          name: 'reset_2fa_success',
          component: AuthResetTwoFaSuccess,
        },
        {
          path: ':loginPageSlug',
          component: AuthLogin,
          name: 'company_login',
        },
      ],
    },
    {
      path: '/register',
      name: 'register',
      component: AuthRegister,
    },
    {
      path: '/register/:referrerLink',
      name: 'register_by_params',
      component: AuthRegister,
    },
    {
      path: '/registration_successful',
      name: 'registration_successful',
      component: RegistrationSuccessful,
    },
    {
      path: '*',
      name: 'not_found',
      component: NotFound,
    },
    {
      path: '/accept_invitations/:token',
      name: 'accept_invitations',
      component: MembersAcceptInvitation,
    },
    {
      path: '/sso_create_profile',
      name: 'sso_create_profile',
      component: SsoCreateProfile,
    },
    {
      path: '/failure',
      name: 'failure',
      component: Failure,
    },
  ],
});
Vue.router = app;

export default app;
