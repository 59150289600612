<template>
  <div class="register-success">
    <img
      src="../../../assets/images/logos/measure-studio-logo-text.svg"
      class="content__paladin-logo register-success__logo"
    >
    <div>
      <div class="register-success__title">
        {{ $t('register.check_your_email_title') }}
      </div>
      <div class="register-success__email">
        {{ $t('register.check_your_email') }}
      </div>
    </div>

    <img
      v-if="displayLinkedinPixel"
      height="1"
      width="1"
      style="display: none;"
      alt=""
      src="https://px.ads.linkedin.com/collect/?pid=1646028&conversionId=1649052&fmt=gif"
    >

    <Footer />
  </div>
</template>

<script>
import Footer from '../../elements/layout/Footer.vue';

export default {
  components: {
    Footer,
  },
  computed: {
    displayLinkedinPixel() {
      return process.env.RAILS_ENV === 'production';
    },
  },
};
</script>

<style lang="scss" scoped>
  .register-success {
    height: 100vh;
    background-color: #e5ebf0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .footer {
      left: 0;
      right: 0;
      width: auto;
      position: relative;
    }
  }

  .register-success__logo {
    margin: 84px auto 0 !important;
  }

  .register-success__title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #1b2938;
    margin-bottom: 12px;
  }

  .register-success__email {
    font-size: 14px;
    text-align: center;
    color: #8798ac;
  }
</style>
