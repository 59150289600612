<template>
  <form @submit.prevent="login">
    <InputBuilder
      v-model="form.fields.code"
      :required="true"
      :label="$t('two_fa.authentication_code')"
      :placeholder="$t('two_fa.enter_code')"
      :errors="form.errors"
      :autocomplete="false"
      class="input-settings"
      name="code"
    />
    <div class="overlay__button-wrapper">
      <Button
        :label="$t('login.login')"
        :loading="form.loading"
        :disabled="!form.fields.code"
        class="button button__primary"
        @click.native="login"
      />

      <div class="two-fa-code__reset-link">
        {{ $t('two_fa.having_problems') }}
        <span class="button__link" @click="goToReset">{{ $t('two_fa.reset') }}</span>
      </div>
    </div>
  </form>
</template>
<script>
import InputBuilder from '../../common/inputs/InputBuilder.vue';
import Button from '../../common/buttons/Button.vue';
import LoginOtpForm from '../../../../forms/login_otp';

export default {
  components: {
    InputBuilder,
    Button,
  },
  data() {
    return {
      form: new LoginOtpForm(),
    };
  },
  methods: {
    async login() {
      if (this.form.loading) {
        return;
      }

      const response = await this.form.submit();

      if (this.form.success) {
        this.$emit('success', response);
      }
    },
    goToReset() {
      this.$router.push({ name: 'reset_2fa' });
    },
  },
};
</script>
<style lang="scss" scoped>
.two-fa-code__reset-link {
  padding-top: 30px;
  color: #8798ac;
  text-align: center;

  .button__link {
    font-weight: 500;
  }
}
</style>
