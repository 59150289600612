<template>
  <div>
    <div class="overlay__title">
      {{ $t('reset_password.reset_password') }}
    </div>
    <div class="overlay__text">
      {{ $t('reset_password.enter_new_password') }}
    </div>

    <div class="overlay__form-wrapper">
      <ResetPasswordForm />
    </div>
  </div>
</template>
<script>
import ResetPasswordForm from '../../elements/auth/reset_password/ResetPasswordForm.vue';

export default {
  components: {
    ResetPasswordForm,
  },
};
</script>
