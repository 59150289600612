import BaseForm from './base';
import authRepository from '../repositories/auth_repository';

export default class ResetTwoFactor extends BaseForm {
  fields = {
    resetCode: '',
  };

  constructor() {
    super();
    authRepository.twoFaReset();
  }

  action() {
    return authRepository.twoFaResetConfirmation({ user: { ...this.fields } });
  }
}
