import moment from 'moment-timezone';
import BaseForm from './base';
import membersRepository from '../repositories/members_repository';

export default class AcceptInvitation extends BaseForm {
  token = null;
  currentData = null;
  timezone = null;
  fields = {
    avatar: '',
    password: '',
    passwordConfirmation: '',
    fullName: '',
    phone: '',
    acceptTerms: false,
    acceptBulkLicense: false,
    marketingMailingConsent: false,
  };

  constructor(token) {
    super();
    this.token = token;
    membersRepository.fetchCurrentData(token)
      .then((data) => {
        this.currentData = data;
      });
    this.timezone = moment.tz.guess();
  }

  canBeSubmitted() {
    if (this.currentData.bulkLicense) {
      return this.fields.acceptTerms && this.fields.acceptBulkLicense;
    }

    return this.fields.acceptTerms;
  }

  action() {
    return membersRepository.register({
      token: this.token,
      params: {
        ...this.fields, timezone: this.timezone,
      },
    });
  }
}
