import _ from 'lodash';
import httpClient from '../utils/http_client';
import { snakeCase } from '../utils/convert_keys';

export default {
  async fetchCurrentData() {
    const response = await httpClient.get('/sso_users/fetch_current_data');

    return response.data;
  },

  async register({ params }) {
    const formData = new FormData();

    if (!params.avatar) {
      delete params.avatar;
    }

    _.each(params, (value, key) => formData.append(snakeCase(key), value));
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = await httpClient.put('sso_users/register', formData, config);

    return response.data;
  },
};
