<template>
  <form class="register-form__container" @submit.prevent="register">
    <InputBuilder
      v-model.lazy="form.fields.fullName"
      name="fullName"
      :label="$t('register.full_name.label')"
      :placeholder="$t('register.full_name.placeholder')"
      :errors="form.errors.fullName"
      class="input-settings"
      required
    />

    <EmailInput
      v-model.lazy="form.fields.email"
      name="email"
      :label="$t('register.email.label')"
      :placeholder="$t('register.email.placeholder')"
      :errors="form.errors.email"
      required
    />

    <InputBuilder
      v-model.lazy="form.fields.password"
      name="password"
      :label="$t('register.password.label')"
      :placeholder="$t('register.password.placeholder')"
      :errors="form.errors.password"
      type="password"
      class="input-settings"
      required
    />

    <InputBuilder
      v-model.lazy="form.fields.passwordConfirmation"
      name="passwordConfirmation"
      :label="$t('register.password_confirmation.label')"
      :placeholder="$t('register.password_confirmation.placeholder')"
      :errors="form.errors.passwordConfirmation"
      type="password"
      class="input-settings"
      required
    />

    <InputBuilder
      v-model.lazy="form.fields.phone"
      :label="$t('profile.fields.phone.label')"
      :errors="form.errors.phone"
      class="input-settings"
    >
      <PhoneInput
        v-model="form.fields.phone"
        :placeholder="$t('profile.fields.phone.placeholder')"
        name="phone"
        class="input-settings--phone"
      />
    </InputBuilder>

    <InputBuilder
      v-model.lazy="form.fields.company"
      name="company"
      :label="$t('register.company.label')"
      :placeholder="$t('register.company.placeholder')"
      :errors="form.errors.company"
      class="input-settings"
      required
    />

    <SelectInput
      v-model="form.fields.companyType"
      name="companyType"
      :label="$t('register.company_type.label')"
      :available-options="companyTypesWithLabels"
      :errors="form.errors.companyType"
      class="input-settings"
      required
    />

    <SelectInput
      v-model="form.fields.companySize"
      name="companySize"
      :label="$t('register.company_size.label')"
      :available-options="companySizesWithLabels"
      :errors="form.errors.companySize"
      class="input-settings"
      required
    />

    <InputBuilder
      v-model.lazy="form.fields.socialsAmount"
      name="companySocialsAmount"
      type="number"
      :label="$t('register.company_socials_amount.label')"
      :placeholder="$t('register.company_socials_amount.placeholder')"
      :errors="form.errors.socialsAmount"
      class="input-settings"
      required
    />

    <InputBuilder
      v-model.lazy="form.fields.socialsTeamSize"
      name="companySocialsTeamSize"
      type="number"
      :label="$t('register.company_socials_team_size.label')"
      :placeholder="$t('register.company_socials_team_size.placeholder')"
      :errors="form.errors.socialsTeamSize"
      class="input-settings"
      required
    />

    <div class="checkbox__container">
      <InputError :errors="form.errors.acceptTerms">
        <Checkbox
          id="acceptTerms"
          v-model="form.fields.acceptTerms"
          name="acceptTerms"
          class="checkbox__terms"
        >
          <span v-html="$t('register.terms.label')" />
        </Checkbox>
      </InputError>
    </div>

    <div class="checkbox__container">
      <Checkbox
        id="mailingConsent"
        v-model="form.fields.marketingMailingConsent"
        name="mailingConsent"
        :label="$t('register.updates.label')"
        class="checkbox__updates"
      />
    </div>

    <div
      v-tooltip="!form.fields.acceptTerms && $t('tooltips.terms')"
      class="register-form__submit"
    >
      <Button
        :disabled="!form.fields.acceptTerms"
        :label="$t('register.create_account')"
        :loading="form.loading"
        class="button__primary button__primary--wide"
        type="submit"
      />
    </div>
  </form>
</template>
<script>
import _ from 'lodash';
import EmailInput from '../../common/inputs/EmailInput.vue';
import InputBuilder from '../../common/inputs/InputBuilder.vue';
import InputError from '../../common/inputs/InputError.vue';
import Button from '../../common/buttons/Button.vue';
import Checkbox from '../../common/forms/Checkbox.vue';
import RegisterForm from '../../../../forms/register';
import SelectInput from '../../common/inputs/SelectInput.vue';
import companyAttributes from '../../../../../config/company_attributes.yml';
import PhoneInput from '../../common/inputs/PhoneInput.vue';

export default {
  components: {
    PhoneInput,
    SelectInput,
    InputBuilder,
    InputError,
    EmailInput,
    Button,
    Checkbox,
  },
  data() {
    return {
      gaEventSent: false,
      form: new RegisterForm(),
    };
  },
  computed: {
    companyTypes() {
      return Object.keys(companyAttributes.company_types);
    },
    companyTypesWithLabels() {
      return _.map(this.companyTypes, key => ({ label: this.$t(`company.company_types.${key}`), value: key }));
    },
    companySizes() {
      return Object.keys(companyAttributes.company_sizes);
    },
    companySizesWithLabels() {
      return _.map(this.companySizes, key => ({ label: this.$t(`company.company_sizes.${key}`), value: key }));
    },
  },
  watch: {
    'form.fields': {
      handler: function track(newVal) {
        if (_.some(_.values(newVal)) && this.$gtag && !this.gaEventSent) {
          this.$gtag.event('data_entry', { event_category: 'registration_flow' });
          this.gaEventSent = true;
        }
      },
      deep: true,
    },
  },
  created() {
    if (!_.isNil(this.$route.params.referrerLink)) {
      try {
        this.$cookies.set('ms-ref', this.$route.params.referrerLink, '1y');
      } catch (e) {}
    }
    this.form.referrerLink = this.referrerLink();
  },
  methods: {
    async register() {
      if (this.loading) {
        return;
      }

      await this.form.submit();

      if (this.form.success) {
        if (window.fbq) {
          window.fbq('track', 'StartTrial');
        }

        if (this.$gtag) {
          this.$gtag.event('form_submitted', { event_category: 'registration_flow' });
          this.$gtag.event('conversion', { send_to: 'AW-10818029567/XlU1CJvKu5ADEP-PuKYo' });
        }

        this.$router.push({ name: 'registration_successful' });
      }
    },
    referrerLink() {
      try {
        return this.$route.params.referrerLink || this.$cookies.get('ms-ref');
      } catch (e) { return null; }
    },
  },
};
</script>

<style lang="scss">
  .login-form__container {
    width: 475px;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .register-form__container {
    max-width: 404px;
  }

  .checkbox__container {
    margin-bottom: 30px;
  }

  .checkbox__updates,
  .checkbox__terms {
    a {
      color: #2e4ce0;
    }
  }

  .checkbox__updates {
    margin-bottom: 60px;
  }
</style>
