<script>
import SsoCreateProfileForm from '../../../forms/sso_create_profile';
import BaseRegistrationForm from './BaseRegistrationForm.vue';

export default {
  extends: BaseRegistrationForm,
  data() {
    return {
      form: new SsoCreateProfileForm(),
    };
  },
  computed: {
    displayPasswordField() {
      return false;
    },
  },
  methods: {
    afterSuccess() {
      window.location = '/';
    },
  },
};
</script>
