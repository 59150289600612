<template>
  <form @submit.prevent="login">
    <EmailInput
      v-model.lazy="form.fields.email"
      name="email"
      :label="$t('login.email.label')"
      :placeholder="$t('login.email.placeholder')"
      tabindex="1"
      required
      :class="{'has-errors': form.errors.length > 0}"
    />

    <RouterLink :to="{name: 'forgot_password'}" class="button__link login-form__forgot-password">
      {{ $t('login.forgot_password') }}
    </RouterLink>

    <InputBuilder
      v-model.lazy="form.fields.password"
      name="password"
      :label="$t('login.password.label')"
      :placeholder="$t('login.password.placeholder')"
      :errors="form.errors"
      type="password"
      class="input-settings"
      tabindex="2"
      required
    />

    <Checkbox
      v-model="form.fields.rememberMe"
      :disabled="form.loading"
      :label="$t('login.keep_me_logged')"
      class="login-form__checkbox"
    />

    <div class="overlay__button-wrapper">
      <Button
        :label="$t('login.login')"
        class="button button__primary"
        type="submit"
        :loading="form.loading"
      />

      <Button
        v-if="ssoProviderName"
        :label="$t('login.login_with', { provider: ssoProviderName })"
        class="button button__primary mt-3"
        @click.native.prevent="ssoLogin"
      />
    </div>

    <div class="overlay__text">
      {{ $t('login.no_account') }}

      <RouterLink
        :to="{name: 'register_by_params', params: { referrerLink: 'weblogin' }}"
        class="button__link login-form__register"
      >
        {{ $t('login.register') }}
      </RouterLink>
    </div>
  </form>
</template>

<script>
import EmailInput from '../../common/inputs/EmailInput.vue';
import InputBuilder from '../../common/inputs/InputBuilder.vue';
import Checkbox from '../../common/forms/Checkbox.vue';
import Button from '../../common/buttons/Button.vue';
import LoginForm from '../../../../forms/login';

export default {
  components: {
    InputBuilder,
    EmailInput,
    Button,
    Checkbox,
  },
  props: {
    ssoProviderName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: new LoginForm(),
    };
  },
  methods: {
    async login() {
      if (this.form.loading) {
        return;
      }
      const response = await this.form.submit();

      if (this.form.success) {
        this.$emit('success', response);
      } else if (this.form.errors[0] === 'two_fa_required') {
        this.$router.push({ name: 'login_otp' });
      }
    },
    ssoLogin() {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = `/auth/openid_connect?login_slug=${this.$route.params.loginPageSlug}`;
      form.style.display = 'none';

      const csrfValue = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const csrf = document.createElement('input');
      csrf.type = 'hidden';
      csrf.name = 'authenticity_token';
      csrf.value = csrfValue;
      form.appendChild(csrf);

      document.body.appendChild(form);
      form.submit();
    },
  },
};
</script>
<style lang="scss" scoped>
  .login-form__forgot-password {
    float: right;
    font-weight: 500;
  }

  .login-form__register {
    font-weight: 500;
  }

  .login-form__checkbox {
    margin-bottom: 30px;
  }
</style>
