<template>
  <div class="register">
    <div class="center-form">
      <img src="../../../assets/images/logos/measure-studio-logo-text.svg" class="content__paladin-logo">
      <div class="overlay__title" v-html="$t('register.try_it_free')" />
      <div class="overlay__text">
        {{ $t('register.no_be_billed') }}
      </div>

      <div class="overlay__form-wrapper">
        <RegisterForm />
      </div>

      <div class="register__go-to-sign-in">
        {{ $t('register.already_have_account') }}
        <RouterLink :to="{name: 'login'}" class="login-header__link">
          {{ $t('register.sign_in') }}
        </RouterLink>
      </div>
    </div>

    <Footer class="footer--pre-pages" />
  </div>
</template>

<script>
import RegisterForm from '../../elements/auth/register/RegisterForm.vue';
import Footer from '../../elements/layout/Footer.vue';

export default {
  components: {
    RegisterForm,
    Footer,
  },
  created() {
    if (this.$gtag) {
      this.$gtag.event('page_load', { event_category: 'registration_flow' });
    }
  },
};
</script>
<style lang="scss" scoped>
  .register {
    background-color: #e5ebf0;
  }
</style>
