<template>
  <div>
    <div class="overlay__title">
      {{ $t('login.welcome_back') }}
    </div>
    <div class="overlay__text">
      {{ $t('login.social_media_simplified') }}
    </div>
    <div class="overlay__form-wrapper">
      <LoginForm :sso-provider-name="ssoProviderName" @success="$emit('success', $event)" />
    </div>
  </div>
</template>
<script>
import LoginForm from '../../elements/auth/login/LoginForm.vue';

export default {
  components: {
    LoginForm,
  },
  props: {
    ssoProviderName: {
      type: String,
      default: null,
    },
  },
  created() {
    if (this.$route.query.message) {
      this.$quickNotice.notify({
        type: 'success',
        body: this.$route.query.message,
      });
    }

    if (this.$route.query.email_confirmed && this.$gtag) {
      this.$gtag.event('email_confirmed', { event_category: 'registration_flow' });
    }
  },
};
</script>
